<template>
	<div>
		<a-navbar/>
		<div id="main-content">
			<router-view/>
		</div>
		<a-footer/>
	</div>
</template>

<script>
export default {
	components: {
		'a-navbar': () => import('./a-navbar.vue'),
		'a-footer': () => import('./a-footer.vue')
	}
}
</script>
<style lang="sass" scoped>
#main-content
	min-height: 53vh

	// @media screen and (max-width: 767px)
	// 	padding-bottom: 76vh
</style>